import React, {useEffect, useState} from 'react'
import CheckBox from '../../components/nu_checkbox'
import { useNavigate } from 'react-router-dom';
import { IoIosInformationCircleOutline } from "react-icons/io";
import Tooltip from "../../components/nu_tooltip";
import { Login, Service } from '../../services/api';
import { AuthContext } from '../../context/auth_context';
import { useContext } from 'react';
import { NotificationContext } from '../../context/notification_context';
import Renderif from '../../utils/renderif';
import NuLoading from '../../components/nu_loading';
import TycosView from './tyco_view';
import TycosViewClinic from './tyco_view';
import { BsEye, BsEyeFill, BsEyeSlash } from 'react-icons/bs';

export default function RegisterClinicaView() {

    const navigate = useNavigate();
    const {user, setUser,logout} = useContext(AuthContext);
    const {notify} = useContext(NotificationContext);
    const [state, setState] = useState({

        // rol_selected:0,
        // password: '',
        // confirmPassword:'',
        // email: '',
        // phone: '',
        // name: '',
        // address:'',
        // provincia: '',
        // cif_nif: '',
        // postal_code:'',
        // contact_name:'',
        // contact_email:'',
        // user_type: 4,
        // errors: {},
        // tycos: false,
        // loading:false,
        // showTyCos:false,
        // showPassword:false,
        // contry_code:'',
        // college:''
        

        rol_selected:0,
        password: 'Password1!',
        confirmPassword:'Password1!',
        email: 'test@test.com',
        phone: '1212121212',
        name: 'Test',
        address:'Direccion',
        provincia: 'Aragón',
        cif_nif: '1234',
        postal_code:'11111',
        contact_name:'Test Name',
        contact_email:'clinica@nursea.es',
        user_type: 4,
        errors: {},
        tycos: false,
        loading:false,
        showTyCos:false,
        showPassword:false,
        contry_code:'',
        college:''
    })

    useEffect(()=>{
        console.log("AA")
        logout();
    },[])

    const provincias = [
        {
            provincia: 'Andalucía',
            colegios: ['Almería', 'Cádiz', 'Córdoba', 'Granada', 'Huelva', 'Jaén', 'Málaga', 'Sevilla']
        },
        {
            provincia: 'Aragón',
            colegios: ['Zaragoza', 'Teruel', 'Huesca']
        },
        {
            provincia: 'Principado de Asturias',
            colegios: ['Asturias']
        },
        {
            provincia: 'Illes Balears',
            colegios: ['Illes Balears']
        },
        {
            provincia: 'Canarias',
            colegios: ['Las Palmas', 'Santa Cruz de Tenerife']
        },
        {
            provincia: 'Cantabria',
            colegios: ['Cantabria']
        },
        {
            provincia: 'Castilla y León',
            colegios: ['Ávila', 'Burgos', 'León', 'Palencia', 'Salamanca', 'Segovia', 'Soria', 'Valladolid', 'Zamora']
        },
        {
            provincia: 'Castilla y la Mancha',
            colegios: ['Albacete', 'Ciudad Real', 'Cuenca', 'Guadalajara y Toledo']
        },
        {
            provincia: 'Cataluña',
            colegios: ['Barcelona', 'Tarragona', 'Girona', 'Lleida']
        },
        {
            provincia: 'Comunidad Valenciana',
            colegios: ['Alicante', 'Valencia', 'Castellón']
        },
        {
            provincia: 'Extremadura',
            colegios: ['Cáceres','Badajoz']
        },
        {
            provincia: 'Galicia',
            colegios: ['A Coruña','Lugo', 'Ourense', 'Pontevedra']
        },
        {
            provincia: 'Murcia',
            colegios: ['Murcia']
        },
        {
            provincia: 'Madrid',
            colegios: ['Madrid']
        },
        {
            provincia: 'Navarra',
            colegios: ['Navarra']
        },
        {
            provincia: 'País Vasco',
            colegios: ['Bizcaia','Gipuzkoa','Araba']
        },
        {
            provincia: 'La Rioja',
            colegios: ['La Rioja']
        },
        {
            provincia: 'Ceuta',
            colegios: ['Ceuta']
        }
        ,
        {
            provincia: 'Melilla',
            colegios: ['Melilla']
        }
        
    ];

    const checkPassword = (password) => {
        var re = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
        return re.test(password);
    }
    const checkEmail = (email) => {
        var re = /^([a-zA-Z0-9._%-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;
        return re.test(email);
    }
    const validator = () => {
        state.errors = {}
        
        if(state.name === ''){
            state.errors.name = 'Nombre completo es obligatorio'
        }
        if(state.contact_name === ''){
            state.errors.contact_name = 'Nombre de contacto es obligatorio'
        }
        if(state.postal_code === ''){
            state.errors.postal_code = 'Código postal es obligatorio'
        }
        if(state.address === ''){
            state.errors.address = 'Dirección es obligatorio'
        }
        if(state.provincia === ''){
            state.errors.provincia = 'Provincia es obligatorio'
        }
        // if(state.phone.length !== 10){
        //     state.errors.phone = 'Teléfono debe tener 10 digitos'
        // }
        if(state.phone === ''){
            state.errors.phone = 'Teléfono es obligatorio'
        }
        // if(state.contry_code === ''){
        //     state.errors.phone = 'Código de pais es obligatorio'
        // }
        if(state.contact_email === ''){
            state.errors.contact_email = 'Correo electrónico es obligatorio'
        }
        if(!checkEmail(state.contact_email)){
            state.errors.contact_email = 'Correo electrónico incorrecto'
        }

        if(!state.password){
            state.errors.password = "La contraseña es obligatoria";
          } else if (!checkPassword(state.password)){
            state.errors.password =
              "Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)";
          }
        if(state.confirmPassword !== state.password){
            state.errors.confirmPassword = 'Las contraseñas no coinciden'
        }
        if(!state.tycos){
            state.errors.tycos = 'Debes leer y aceptar los Términos y Condiciones'
        }
        if(state.cif_nif === ''){
            state.errors.cif_nif = 'CIF/NIF es obligatorio'
        }
   
        setState({...state})
        if(Object.keys(state.errors).length > 0){
            
            return false;
        }
       return true;
        

    }
    const onRegister = async() => {
        if(!validator()){
            return;
        }
        setState({...state, loading:true})

        let body = {
            password: state.password,
            phone: '+34'+state.phone,
            name: state.name,
            cif_nif: state.cif_nif,
            address:state.address,
            province: state.college,
            community: state.provincia,
            postal_code: state.postal_code,
            contact_name: state.contact_name,
            contact_email: state.contact_email,
            user_type: state.user_type
        }

       
        let _register = await Service('POST','authentication/register/organization', JSON.stringify(body));

        if(_register.status === 200){

            let _login =  await Login({email:body.contact_email, password:body.password}) 
         
            localStorage.setItem('user',JSON.stringify(_login.data.user))
            localStorage.setItem('token',_login.data.token)
            localStorage.setItem('refreshToken',_login.data.refreshToken)
        

           

        
            let _userdata = await Service('GET', `organizationData/getByIdUser/${_login.data.user.id}`);
         
         
            let user = _login.data.user
            user.picture = '../../assets/avatar.png'
            setUser({user:user, user_data:_userdata.data.organizationData})

            let _welcome = await Service('POST', `user/welcomeOrganization/${_userdata.data.organizationData.idUser}`)
       
         
            navigate('/clinic/center')

         
          

            
        }else{
            notify({title:'Error', message:_register?.response?.data?.error, type:'error'})
            setState({...state, loading:false})
        }

    }
  return (
    <>
    <Renderif isTrue={state.showTyCos}>
        <TycosViewClinic onClose={()=>{
            setState({...state, showTyCos:false})
        }}></TycosViewClinic>

        
    </Renderif>
    <Renderif isTrue={state.loading}>
        <NuLoading></NuLoading>
    </Renderif>
    <div className='container-fluid h-100'>

        <div className='d-flex h-100'>

            <div className='col-12 col-md-3 d-none d-lg-block'>
                <div className='register_bg bg-cover' style={{backgroundImage:'url(/assets/nursea5.png)'}}></div>
            </div>
            
            <div className='col-12 col-lg-9 d-flex flex-column p-3 p-sm-5 overflow-auto hide-scroll'>

                {/* <div className='col d-flex justify-content-center align-items-center'>
                    <img  src='/assets/logo.png'></img>
                </div> */}


                <div className='container d-flex flex-column'>
       
                    <div className='f-28 bold'>Sobre ti</div>

                    <div className='d-flex mt-3'>

                        <div className='row w-100'>

                            <div className='col-12 col-md-6 pe-0 pe-md-4'>
                                
                                <div className='f-16 bold mt-3 mb-1'>Nombre de la organización</div>
                                <input type='text' value={state.name} onChange={(evt) => {
                                    setState({...state, name:evt.currentTarget.value})
                                }} className='nu_input' placeholder='Nombre de la organización*'></input>
                                <div className='text-danger f-12'>{state.errors?.name}</div>

                                <div className='f-16 bold mt-3 mb-1'>Dirección completa</div>
                                <input type='text' value={state.address} onChange={(evt) => {
                                    setState({...state, address:evt.currentTarget.value})
                                }} className='nu_input' placeholder='Dirección completa *'></input>
                                <div className='text-danger f-12'>{state.errors?.address}</div>

                                <div className='d-flex flex-wrap'>


                                    <div className='col-12 col-md'>
                                        <div className='f-16 bold mt-3 mb-1'>Comunidad Autonoma</div>
                                        <select defaultValue="" onChange={(evt)=>{
                                            
                                            setState({...state, provincia:evt.currentTarget.value})

                                        }}  className='nu-select nu-input--light'>
                                        <option value="" disabled>Seleccionar</option>
                                        {
                                            provincias.map((item, i)=>{
                                                return(
                                                    <option key={i} value={item.provincia}>{item.provincia}</option>
                                                )
                                            })
                                        }
                                
                                        </select>
                                        <div className='text-danger f-12'>{state.errors?.provincia}</div>
                                    </div>

                                    <div className='col-12 col-md ms-0 ms-md-2'>
                                        <div className='f-16 bold mt-3 mb-1'>Provincia</div>
                                        <select defaultValue=""  className='nu-select nu-input--light' onChange={(evt)=>{
                                                state.college = evt.currentTarget.value;
                                                setState({...state })


                                            }}>
                                            <option value="">Seleccionar</option>
                                            {
                                                provincias.filter((provincia)=> provincia.provincia === state.provincia)[0]?.colegios?.map((item, i)=>{

                                                    
                                                    return(
                                                        <option key={i} value={item}>{item}</option>
                                                    )
                                                })
                                            }
                                    
                                        </select>
                                        <div className='text-danger f-12'>{state.errors?.college}</div>
                                    </div>

                                </div>

                                <div className='f-16 bold mt-3 mb-1'>Contraseña</div>
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <input type={state.showPassword ? 'text' : 'password'} value={state.password}
                                        onChange={(evt) => {
                                        const newPassword = evt.currentTarget.value;
                                        let newErrors = { ...state.errors };
                                        if (!newPassword) {
                                             newErrors.password = "La contraseña es obligatoria";
                                        } else if (!checkPassword(newPassword)) {
                                            newErrors.password =
                                            "Debe tener mínimo 8 caracteres, 1 letra Mayúscula, 1 letra minúscula, 1 número y 1 carácter especial (!&%#*?-^/)";
                                        } else {
                                            delete newErrors.password;
                                        }
                                        setState({
                                            ...state,
                                             password: newPassword,
                                            errors: newErrors,
                                        });
                                        }}
                                        className='nu_input' placeholder="Contraseña*" style={{ flex: 1 }}></input>
                                        <div className='nu-icon nu-icon--mid cursor-pointer ms-2'
                                            onClick={() => { 
                                                    setState({
                                                    ...state,
                                                    showPassword: !state.showPassword,
                                                });
                                            }} style={{ marginLeft: "8px" }}>
                                                <Renderif isTrue={state.showPassword}>
                                                <BsEye size={30}></BsEye>
                                                </Renderif>
                                                <Renderif isTrue={!state.showPassword}>
                                                <BsEyeSlash size={30}></BsEyeSlash>
                                                </Renderif>
                                            </div>
                                    </div>
                                    <div className='text-danger f-12'>{state.errors?.password}</div>
                            </div>

                            <div className='col-12 col-md-6 ps-0 ps-md-4'>
                                <div className='f-16 bold mt-3 mb-1'>CIF / NIF</div>
                                <input type='text' value={state.cif_nif} onChange={(evt) => {
                                    setState({...state, cif_nif:evt.currentTarget.value})
                                }} className='nu_input' placeholder='CIF / NIF (si particular)'></input>
                                <div className='text-danger f-12'>{state.errors?.cif_nif}</div>

                                <div className='f-16 bold mt-3 mb-1'>Código postal</div>
                                <input type='text' value={state.postal_code} onChange={(evt) => {
                                    setState({...state, postal_code:evt.currentTarget.value})
                                }} className='nu_input' placeholder='Código postal *'></input>
                                <div className='text-danger f-12'>{state.errors?.postal_code}</div>

                                <div className='f-16 bold mt-3 mb-1'>Teléfono</div>

                                <div className='d-flex'>

                                    <div className='col-2'>
                                        <input type='number' disabled value={state.contry_code} onChange={(evt)=>{
                                            setState({...state, contry_code: evt.currentTarget.value})
                                        }}  className='nu_input' placeholder='+34'></input>
                                    </div>

                                
                                    <div className='col ps-1'>
                                        <input type='number' value={state.phone} onChange={(evt)=>{
                                                        setState({...state, phone: evt.currentTarget.value})
                                                    }}  className='nu_input' placeholder='Teléfono*'></input>
                                    </div>
                                
                                    
                                </div>
                                <div className='text-danger f-12'>{state.errors?.phone}</div>

                                <div className='f-16 bold mt-3 mb-1'>Confirmar contraseña</div>
                                <input type={state.showPassword ? 'text' : 'password'} value={state.confirmPassword}
                                onChange={(evt) => {
                                const newConfirmPassword = evt.currentTarget.value;
                                let newErrors = { ...state.errors };
                                if (!newConfirmPassword) {
                                newErrors.confirmPassword =
                                    "Confirmar la contraseña es obligatorio";
                                } else if (newConfirmPassword !== state.password) {
                                newErrors.confirmPassword =
                                    "Las contraseñas no coinciden";
                                } else {
                                delete newErrors.confirmPassword;
                                }
                                setState({
                                ...state,
                                confirmPassword: newConfirmPassword,
                                errors: newErrors,
                                });
                            }}
                            className='nu_input' placeholder="Confirmar contraseña *"></input>
                            <div className='text-danger f-12'>{state.errors?.confirmPassword}</div>
                            </div>

                        </div>
                        
                    </div>

                    
                   <div className='d-flex align-items-center mt-5'> 
                     <div className='f-18 bold'>Persona de referencia contratación:</div>

                     <div className='nu-icon nu-icon--mid ms-1 cursor-pointer'>
                        <Tooltip content="Es la persona que va a recibir la información de los Profesionales asignados para tramitar la contratación.">
                            <IoIosInformationCircleOutline size={24} className='cursor-pointer'/>
                        </Tooltip>
                     </div>

                   </div>

                    <div className='d-flex'>

                        <div className='row'>

                       

                            <div className='col-12 col-md-6 pe-0 pe-md-4'>
                                <div className='f-16 bold mt-3 mb-1'>Nombre completo</div>
                                <input type='text' value={state.contact_name} onChange={(evt) => {
                                    setState({...state, contact_name:evt.currentTarget.value})
                                }}  className='nu_input' placeholder='Nombre completo *'></input>
                                    <div className='text-danger f-12'>{state.errors?.contact_name}</div>
                            </div>

                            <div className='col-12 col-md-6 ps-0 ps-md-4'>
                                <div className='f-16 bold mt-3 mb-1'>Correo electrónico</div>
                                <input type='text' value={state.contact_email} onChange={(evt) => {
                                    setState({...state, contact_email:evt.currentTarget.value})
                                }}  className='nu_input' placeholder='Correo electrónico *'></input>
                                    <div className='text-danger f-12'>{state.errors?.contact_email}</div>
                            </div>

                        </div>
                        
                    </div>

                    <div className='mt-5'>
                        <div className='d-flex justify-content-start align-items-start'>
                            <CheckBox checked={state.tycos} onChange={(evt)=>{
                                setState({...state, tycos:evt.value})
                            }}   text=""></CheckBox>
                            <div>He leído y estoy de acuerdo con los <span className='cursor-pointer bold text-lightblue text-decoration-underline' onClick={()=>{
                                setState({...state, showTyCos:true})
                            }}>Términos y Condiciones</span> de nursea.es. <br></br></div>
                          
                        </div>
                        <div className='text-danger f-12'>{state.errors?.tycos}</div>
                    </div>

                   
                   


                    <div className='mt-5'>
                        <div className='nu_btn nu_btn--overlay' onClick={async ()=> {


                            
                            onRegister()
                            // setUser({user:{idUserType:4}})
                        
                            // navigate('/clinic/center')
                         
                        
                        }}>
                            <div className='nu_btn-text f-18'>Siguiente</div>
                            <div className='nu_btn-overlay'></div>
                        </div>
                    </div>

                    <div className='d-block d-md-none my-5'></div>

                    
                </div>



                <div className='col'></div>
                

                {/* <div className='d-flex justify-content-evenly my-3 mx-2'>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Terminos y condiciones</div>
                    <div className='f-12 bold text-decoration-underline cursor-pointer'>Aviso de privacidad</div>
                </div> */}

                

                
                
            </div>

        </div>

    </div>
    </>
  )
}
